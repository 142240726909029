import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/74640f51966703f989bb4a78a917f428/aa440/Morne_Magic_university_gothic_architecture_sprawling_wintertime_aaf9cfea-a468-441b-a358-46a85b157616.png",
            "srcSet": ["/static/74640f51966703f989bb4a78a917f428/5ff7e/Morne_Magic_university_gothic_architecture_sprawling_wintertime_aaf9cfea-a468-441b-a358-46a85b157616.png 375w", "/static/74640f51966703f989bb4a78a917f428/1d69c/Morne_Magic_university_gothic_architecture_sprawling_wintertime_aaf9cfea-a468-441b-a358-46a85b157616.png 750w", "/static/74640f51966703f989bb4a78a917f428/aa440/Morne_Magic_university_gothic_architecture_sprawling_wintertime_aaf9cfea-a468-441b-a358-46a85b157616.png 1500w", "/static/74640f51966703f989bb4a78a917f428/71c1d/Morne_Magic_university_gothic_architecture_sprawling_wintertime_aaf9cfea-a468-441b-a358-46a85b157616.png 1536w"],
            "width": "500px",
            "className": "center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Basentia Omnia`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The Basentia Omnia is perhaps the most famous University in all of Eastern Naos. Located on the island of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{` in the city of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`.`}</p>
    <p>{`The University is most well known for training `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` in the ways of using `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`, although other branches of academia are also studied within the Basentia, such as Science, Philosophy, Medicine, Technology, and Politics.`}</p>
    <h2>{`Notable Professors at the Basentia`}</h2>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Enzo%20Quint",
          "title": "Enzo Quint"
        }}>{`Enzo Quint`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      